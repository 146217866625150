@import '../../../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

/* ================ Modal ================ */

.modalHeaderWrapper {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.listingLink {
  width: fit-content;
  height: fit-content;
}
.submitButtonT {
  flex-shrink: 0;
  margin-top: auto;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
  }
}

.thumbnail {
  width: 500px;
  height: 400px;
}

.certificateImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 400px;

  @media (--viewportMedium) {
    width: 800px;
    height: 800px;
  }
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-bottom: 50px;
}

.checkoutButton {
  background-color: #e8a316;

  &:not(:disabled) {
    &:hover {
      background-color: #e8a316;
    }
  }
}

.packagingButton {
  background-color: var(--colorSuccess);
  margin-bottom: 20px;


    &:hover {
      background-color: green;
  }
}

.price {
  margin-top: 20px;
  font-size: 22px;
  font-weight: 700;
  color: #0056cb;
}

.title {
  font-size: 30px;
  margin: 10px 0;
  font-weight: 700;
  color: #000;
}

.breed {
  color: #0056cb;
  font-size: 18px;
}

.actionsWrapper {
  cursor: pointer;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
