.socialtitle {
  color: var(--colorBlack);
  text-align: center;
}

.copybutton {
  background-color: var(--colorGrey700);
  color: var(--colorWhite);
  font-weight: normal;
  font-size: small;
  border-radius: 5px;
  display: block;
  margin: 10px auto;
  text-align: center;
}

.copybutton:hover {
  cursor: pointer;
}

.copy {
  color: var(--marketplaceColor);
}

.copied {
  color: var(--colorSuccess);
  text-align: center;
  display: block;
  margin-top: 10px;
  text-align: center;
}

.socialcontainer {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  max-width: 250px;
  margin: auto auto;
  justify-content: center;
}

.sharebutton:hover:not(:active) {
  opacity: 0.75;
}

.emailbutton {
    border: none;
}

.emailbutton:hover:not(:active) {
    opacity: 0.75;
    cursor: pointer;
  }